/* stylelint-disable */
@font-face {
    font-family: edenred;
    src: url("../fonts/edenred_bold-webfont.woff2") format("woff2"),
        url("../fonts/edenred_bold-webfont.woff") format("woff");
    font-weight: 700;
    font-style: normal;
}

// 00 . Colors 🟡
// 00 . Breakpoints 🟢
// 00 . Typography 🟢
// 00 . Forms 🔴
// 00 . Buttons 🔴
// 00 . Tooltips 🔴

// 00 . Colors
$palette: (
    "dark-blue": #162056,
    "black": #000,
    "white": #fff,
    "gray": #e1e1e1,
    "light-gray": #f4f4f4,
    "mid-gray": #9c9c9c,
    "dark-gray": #656565,
    "green": #04724d,
    "red": #f72717,
    "danger": #b40303,
    "yellow": #ffc72c,
    "light-blue": #28c0d4,
    "light-green": #1bb934,
    "cold-white": #f1f7ff,
    "cta-linear-gradient-base": #182a64,
    "cta-linear-gradient": linear-gradient(270deg, #094c9c 0.79%, #323d8f 53.08%, #182a64 99.21%),
    "cta-linear-gradient-darker":
        linear-gradient(
            270deg,
            darken(#094c9c, 5) 0.79%,
            darken(#323d8f, 5) 53.08%,
            darken(#182a64, 5) 99.21%
        ),
    "blue-gradient-base": #182a64,
    "blue-gradient":
        linear-gradient(
            281.14deg,
            #182a64 0.6%,
            #2b408f 24.34%,
            #094c9c 50.38%,
            #2b408f 76.22%,
            #182a64 100%
        ),
    "yellow-gradient-base": #ffcb61,
    "yellow-gradient":
        linear-gradient(90deg, #ffcb61 0%, rgba(255, 203, 97, 0.5) 53.5%, #ffcb61 100%),
    "pink-gradient-base": #ebd0f3,
    "pink-gradient":
        linear-gradient(90deg, #ebd0f3 20%, rgba(235, 208, 243, 0.3) 47.5%, #ebd0f3 80%),
    "green-element-gradient": linear-gradient(90deg, #00a184 0%, #00a184 48.5%, #50d3bb 100%),
    "green-cta-gradient": linear-gradient(90deg, #00a184 0%, #50d3bb 50%, #00a184 100%),
    "green-cta-gradient-darker":
        linear-gradient(
            90deg,
            darken(#00a184, 10) 0%,
            darken(#50d3bb, 10) 50%,
            darken(#00a184, 10) 100%
        ),
    "keyword-gradient":
        linear-gradient(
            92deg,
            #f72717 0%,
            #ed1f7d 25%,
            #711cff 51.56%,
            #0d8aff 77.08%,
            #05e4c5 100%
        ),
    "color-francia-gradient":
        linear-gradient(
            134.29deg,
            #f72717 -0.09%,
            #ed1f7d 22.95%,
            #711cff 45.97%,
            #0d8aff 68.99%,
            #05e4c5 91.99%
        ),
    "pane-content-gradient":
        linear-gradient(
            145deg,
            #f1f7ff 20%,
            rgba(255, 255, 255, 0.6) 36.69%,
            rgba(254, 208, 200, 0.2) 52.77%,
            rgba(200, 195, 255, 0.2) 66.06%,
            rgba(176, 239, 255, 0.3) 79.36%
        ),
);
/* stylelint-enable */

$theme-colors: (
    "light-green": map-get($palette, "light-green"),
);

$primary-color: map-get($palette, "dark-blue");
$success-color: map-get($palette, "green");
$error-color: map-get($palette, "red");
$warning-color: map-get($palette, "yellow");

$dark-blue: map-get($palette, "dark-blue");
$cold-white: map-get($palette, "cold-white");
$cta-linear-gradient-base: map-get($palette, "cta-linear-gradient-base");
$cta-linear-gradient: map-get($palette, "cta-linear-gradient");
$cta-linear-gradient-darker: map-get($palette, "cta-linear-gradient-darker");
$blue-gradient-base: map-get($palette, "blue-gradient-base");
$blue-gradient: map-get($palette, "blue-gradient");
$yellow-gradient-base: map-get($palette, "yellow-gradient-base");
$yellow-gradient: map-get($palette, "yellow-gradient");
$pink-gradient-base: map-get($palette, "pink-gradient-base");
$pink-gradient: map-get($palette, "pink-gradient");
$green-element-gradient: map-get($palette, "green-element-gradient");
$green-cta-gradient: map-get($palette, "green-cta-gradient");
$green-cta-gradient-darker: map-get($palette, "green-cta-gradient-darker");
$keyword-gradient: map-get($palette, "keyword-gradient");
$color-francia-gradient: map-get($palette, "color-francia-gradient");
$pane-content-gradient: map-get($palette, "pane-content-gradient");

$black: map-get($palette, "black");
$white: map-get($palette, "white");
$gray: map-get($palette, "gray");
$light-gray: map-get($palette, "light-gray");
$mid-gray: map-get($palette, "mid-gray");
$dark-gray: map-get($palette, "dark-gray");

$green: map-get($palette, "green");
$light-green: map-get($palette, "light-green");
$red: map-get($palette, "red");
$danger: map-get($palette, "danger");
$yellow: map-get($palette, "yellow");
$light-blue: map-get($palette, "light-blue");

// TODO: remove/refactor
$green: #04724d;
$body-color: $black;
$darkBlue: #162056;
$primary: $darkBlue;
$text-muted: #b7b7b7;

// 00 . Breakpoints
$container-max-widths: (
    sm: 768px,
    md: 992px,
    lg: 1200px,
    xl: 1201px,
);

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1400px,
);

// UI
$icon-plus-francia: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13 13" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M5.36523 5H2.36523C1.53723 5 0.865234 5.671 0.865234 6.5C0.865234 7.329 1.53723 8 2.36523 8H5.36523V11C5.36523 11.829 6.03723 12.5 6.86523 12.5C7.69523 12.5 8.36723 11.829 8.36723 11V8H11.3652C12.1942 8 12.8652 7.329 12.8652 6.5C12.8652 5.671 12.1942 5 11.3652 5H8.36723V2C8.36723 1.171 7.69523 0.5 6.86723 0.5C6.03723 0.5 5.36523 1.171 5.36523 2V5Z" fill="url(%23paint0_linear_1697_24817)"/><defs><linearGradient id="paint0_linear_1697_24817" x1="0.865234" y1="0.5" x2="13.2358" y2="0.894999" gradientUnits="userSpaceOnUse"><stop stop-color="%23F72717"/><stop offset="0.25" stop-color="%23ED1F7D"/><stop offset="0.515625" stop-color="%23711CFF"/><stop offset="0.770833" stop-color="%230D8AFF"/><stop offset="1" stop-color="%2305E4C5"/></linearGradient></defs></svg>');
$icon-minus-francia: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13 4" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M2 3.65039H11C11.828 3.65039 12.5 2.97939 12.5 2.15039C12.5 1.32239 11.828 0.650391 11 0.650391H2C1.171 0.650391 0.5 1.32239 0.5 2.15039C0.5 2.97939 1.171 3.65039 2 3.65039Z" fill="url(%23paint0_linear_1485_32102)"/><defs><linearGradient id="paint0_linear_1485_32102" x1="0.5" y1="0.650391" x2="12.6844" y2="2.20661" gradientUnits="userSpaceOnUse"><stop stop-color="%23F72717"/><stop offset="0.25" stop-color="%23ED1F7D"/><stop offset="0.515625" stop-color="%23711CFF"/><stop offset="0.770833" stop-color="%230D8AFF"/><stop offset="1" stop-color="%2305E4C5"/></linearGradient></defs></svg>');

// 00 . Typography
$font-family-base: "Ubuntu", sans-serif;
$font-edenred-base: edenred, sans-serif;
$font-weight-light: 300;
$font-weight-default: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;
$headings-font-weight: $font-weight-medium;
$headings-margin-bottom: 10px;
$line-height-lg: 1.3125;
$line-height-sm: 1.3125;
$line-height-base: 1.3125;
$paragraph-margin-bottom: 10px;

// 00 . Forms
$enable-validation-icons: false;
$input-font-weight: $font-weight-light;
$input-border-radius: 10px;
$input-border-color: $gray;
$form-feedback-valid-color: $gray;
$form-feedback-icon-valid-color: $light-green;
$input-padding-y: 0.8rem;
$input-line-height: 1;
$input-btn-line-height: 1;
$custom-select-indicator: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='4' height='2' viewBox='0 0 4 2'><polygon fill='#343a40' points='2 2 0 0 4 0'></polygon></svg>");
$input-color: $black;

// 00 . Buttons
$btn-padding-y: $input-padding-y;
$btn-padding-x: 1.5em;
$btn-padding-y-lg: 14px;
$btn-padding-x-lg: 3em;

$btn-font-size: 14px;
$btn-font-size-lg: 16px;

$btn-border-radius: 30px;
$btn-border-radius-lg: $btn-border-radius;
$btn-border-radius-sm: $btn-border-radius;

$btn-font-weight: 500;

// 00 . Tooltips
$tooltip-opacity: 1;
$tooltip-arrow-color: #094c9c;

// 00. Boxes
$box-radius-default: 10px;
$box-padding-y-xs: 20px;
$box-padding-x-xs: 15px;
$box-padding-y-lg: 30px;
$box-padding-x-lg: 30px;
$box-spacing-xs: 36px;
$box-spacing-lg: 40px;

$modal-content-border-radius: 0;
$modal-content-border-width: 0;
